const defaultSurveyJSONDE = { 
 "title": "Nachhaltigkeitsbefragung", 
 "pages": [{ 
 "name": "page1", 
 "title": "BASISDATEN", 
 "elements": [
{
  "type": "text",
  "name": "accomodationToken",
  "title": "Unterkunfts Token",
  "isRequired": true,
  "requiredErrorText": "Unterkunfts Token kann auf der Unterkunftsprofilseite generiert weden"
}
,{
  "type": "text",
  "name": "question1.2",
  "title": "Anzahl Mitarbeitende in Voll- und Teilzeit",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "dropdown",
  "name": "question1.3",
  "title": "Land",
  "isRequired": true,
  "requiredErrorText": "",
  "choicesByUrl": {
    "url": "https://surveyjs.io/api/CountriesExample"
  }
}
,{
  "type": "text",
  "name": "question1.4",
  "title": "Gesamtanzahl Zimmer",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.5",
  "title": "Gesamtanzahl Betten",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.6",
  "title": "Gesamtanzahl Übernachtungen/Jahr (Durchschnittsjahr)",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}

 ] 
 },{ 
 "name": "page2", 
 "title": "MOBILITÄT", 
 "elements": [
{
  "type": "boolean",
  "name": "question2.1",
  "title": "Für unsere eigenen Geschäftsreisen haben wir Nachhaltigkeitsrichtlinien festgelegt und verfolgen diese konsequent",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.2",
  "title": "Wir verzichten bewusst auf Dienstwägen mit Verbrennungsmotor",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.3",
  "title": "Unsere Mitarbeitenden sind geschult, den Umwelteinfluss verschiedener Transportmittel in Hinblick auf die An- und Abreise sowie die Mobilität vor Ort, abzuwägen und dementsprechend zu beraten.",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.4",
  "title": "Wir stellen jedem Gast ein kostenreduziertes oder kostenfreies Ticket für lokale Verkehrsbetriebe zur Verfügung",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.5",
  "title": "Wir bieten umweltfreundliche Transportmöglichkeiten an, wie Radvermietung, Carsharing, Park & Ride Parkplätze",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question2.6",
  "title": "Wir haben vor Ort die folgende Anzahl an E-Ladestationen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "<= 3"
    },
     {
      "value": "2",
      "text": "<=10"
    },
     {
      "value": "3",
      "text": "> 10"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine"
}
,{
  "type": "boolean",
  "name": "question2.7",
  "title": "Unsere E-Ladestationen sind kompatibel mit mehr als einem Ladesteckertyp",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.8",
  "title": "Wir weisen mind. eine Ladesäule mit einer Ladegeschwindigkeit mehr als als 11kwH auf",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.9",
  "title": "Wir bieten allen mit dem E-Mobil Anreisenden die Dienstleistung an, für sie das Auto aufzuladen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page3", 
 "title": "WASSER & ENERGIE", 
 "elements": [
{
  "type": "boolean",
  "name": "question3.1",
  "title": "Wir haben Expert*innen in unserem Betrieb, die sich mit Energie- und Wasserverbrauch auskennen oder holen uns externe Hilfe (z.B. in Deutschland bezuschusst durch das Bundesamt für Wirtschaft und Ausfuhrkontrolle)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.2",
  "title": "Alle unsere verwendeten Gerätschaften (z.B. Kühlschränke, Waschmaschinen, TV-Geräte) sind auf dem aktuellen Stand und gehören der ressourcensparendsten Kategorie an (z.B. in Deutschland Effizienzklasse A)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.3",
  "title": "Unser Stromverbrauch/Gesamtanzahl der Übernachtungen im vergangenen Jahr lag bei ...kWh ",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.4",
  "title": "Wir benutzen autarke Strom- und Wärmesysteme:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Solar"
    },
     {
      "value": "2",
      "text": "Blockkraft"
    },
     {
      "value": "3",
      "text": "Wärmepumpen"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "text",
  "name": "question3.5",
  "title": "Unser Trinkwasserverbrauch/Übernachtung im vergangenen Jahr betrug ...L",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.6",
  "title": "Wir nutzen Regenwasser für die folgenden Anwendungen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Toilette"
    },
     {
      "value": "2",
      "text": "Dusche"
    },
     {
      "value": "3",
      "text": "Bewässerung"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question3.7",
  "title": "Wir ergreifen folgende Maßnahmen zur Reduktion des Wasserverbrauchs:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Transparenz des Wasserverbrauchs und Tipps zur Reduktion an die Gäste"
    },
     {
      "value": "2",
      "text": "Nutzung von Perlstrahlern"
    },
     {
      "value": "3",
      "text": "Nutzung von Sparduschköpfen"
    },
     {
      "value": "4",
      "text": "Reparieren von Verdichtungs- und Kalkproblemen in weniger als 24h"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question3.8",
  "title": "Bei der Wasserversorgung achten wir darauf, lokale Bedürfnisse nicht zu beschränken. Die Unterkunft trägt nicht zu einer Wasserknappheit und signifikanten Preissteigerung bei",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.9",
  "title": "Wir haben KPIs und Verfahren zur kontinuierlichen Kontrolle und Verbesserung zur Reduktion des Wasser- und Energieverbrauchs festgelegt",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.10",
  "title": "Wir reduzieren den Energieverbrauch durch Angebote der gemeinsamen Nutzung (z.B. Gerätschaften in Gemeinschaftsräumen (z.B. Gemeinschaftskühlschrank statt Minibar, Gemeinschafts-TV))",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.11",
  "title": "Der Anteil an LED-Beleuchtung in unserer Unterkunft beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question3.12",
  "title": "Wir minimieren den Energieverbrauch für Strom durch die Nutzung von Bewegungsmeldern in kurz besuchten Bereichen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.13",
  "title": "Wir prüfen mind. 1x jährlich die Effizienz der Heizungs- sowie falls vorhanden Klima- und Lüftungsanlagen und ergreifen falls notwendig Maßnahmen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question3.14",
  "title": "Wir nutzen folgenden Stromanbieter:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Bürgerwerke"
    },
     {
      "value": "2",
      "text": "Elektrizitätswerke Schönau"
    },
     {
      "value": "3",
      "text": "Green City Power"
    },
     {
      "value": "4",
      "text": "Green Planet Energy"
    },
     {
      "value": "5",
      "text": "Grün.Power"
    },
     {
      "value": "6",
      "text": "Mann Strom"
    },
     {
      "value": "7",
      "text": "Naturstrom"
    },
     {
      "value": "8",
      "text": "Ökostrom+Polarstrom"
    },
     {
      "value": "9",
      "text": "Strom von Föhr"
    },
     {
      "value": "10",
      "text": "Fair Trade Power"
    },
     {
      "value": "11",
      "text": "Westfalenwind Strom"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine"
}

 ] 
 },{ 
 "name": "page4", 
 "title": "VERBRAUCH & ABFALL", 
 "elements": [
{
  "type": "text",
  "name": "question4.1",
  "title": "Unsere verbrauchte Abfallmenge an Restmüll/Übernachtung im vergangenen Jahr betrug ...L",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question4.2",
  "title": "Wir ergreifen Maßnahmen, um die Abfallmenge konstant zu reduzieren, und zwar folgende:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Keine Verwendung von Einwegstrohhalmen"
    },
     {
      "value": "2",
      "text": "Unverpackte Give Aways und Snacks (z.B. lose Kekse, Nussgläser statt -dosen, Süßigkeitenbox mit Zange statt verpackte) "
    },
     {
      "value": "3",
      "text": "Mehrwegflaschen auf dem Hotelzimmer"
    },
     {
      "value": "4",
      "text": "Nachfüllstationen statt Einweggetränkeautomaten"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question4.3",
  "title": "In unserer Unterkunft erfolgt eine konsequente Mülltrennung in den folgenden Bereichen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Küche"
    },
     {
      "value": "2",
      "text": "Zimmer"
    },
     {
      "value": "3",
      "text": "Gänge"
    },
     {
      "value": "4",
      "text": "Rezeption"
    },
     {
      "value": "5",
      "text": "Aufenthalts-/Tagungsräume"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "radiogroup",
  "name": "question4.4",
  "title": "In Räumlichkeiten ohne Mülltrennung erfolgt eine Nachsortierung durch das Personal:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Ja"
    },
     {
      "value": "2",
      "text": "Nein"
    },
     {
      "value": "3",
      "text": "Wir haben überall konsequente Mülltrennung"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question4.5",
  "title": "Wir stellen den Gästen und Mitarbeitenden Informationen zur Müllvermeidung und dem adäquaten Umgang mit Müll zur Verfügung",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question4.6",
  "title": "Wir dokumentieren die Mülltrennung und ergreifen evtl. Maßnahmen um diese zu verbessern",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.7",
  "title": "Um die Umweltschäden durch den anfallenden Abfall zu minimieren, ergreifen wir folgende Maßnahmen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Verwendung biologisch abbaubarer Wasch-, Putz- und Pflegemittel ohne Mikroplastik und andere Kunststoffe "
    },
     {
      "value": "2",
      "text": "Filtern von Abwasser falls Umweltschäden anfallen könnten"
    },
     {
      "value": "3",
      "text": "Stoffgerechte Entsorgung und Unschädlichmachung potentiell schädlicher Stoffe"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "checkbox",
  "name": "question4.8",
  "title": "Wir reduzieren die verwendete Menge von Frischpapier durch folgende Maßnahmen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Verwendung von digitalen Lösungen wo möglich"
    },
     {
      "value": "2",
      "text": "Verwendung von Recyclingpapier"
    },
     {
      "value": "3",
      "text": "Doppelseitiger Druck wo möglich"
    },
     {
      "value": "4",
      "text": "Verwendung von einfach bedrucktem Papier als Notizblöcke und, oder Schmierpapier"
    },
     {
      "value": "5",
      "text": "Sparsame Ausgabe von Notizblöcken an Gäste und Mitarbeitende"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "checkbox",
  "name": "question4.9",
  "title": "Für nicht zu reduzierenden Papierverbrauch nutzen wir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Papiersparende Designs"
    },
     {
      "value": "2",
      "text": "Farbsparende Designs"
    },
     {
      "value": "3",
      "text": "Nachhaltige Farben"
    },
     {
      "value": "4",
      "text": "Bei Aufträgen umweltfreundliche Druckereien"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "radiogroup",
  "name": "question4.10",
  "title": "Im gesamten Berieb gehen wir wie folgt mit Einwegmaterialien um:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Wir verzichten vollständig auf Einwegmaterialien"
    },
     {
      "value": "2",
      "text": "Falls wir Einwegmaterialien einsetzen sind diese auschlieslich aus kompostierbaren Materialien"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine"
}
,{
  "type": "boolean",
  "name": "question4.11",
  "title": "Wir verringern Abfallmaterialien im Versand durch Präferenz von digitalen Versandoptionen anstelle von physischen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.12",
  "title": "Wir drucken Infomaterialien nur wo nötig. Alternativ verwenden wir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "QR Codes"
    },
     {
      "value": "2",
      "text": "Informationen auf der Webseite"
    },
     {
      "value": "3",
      "text": "Informationen an allgemein zugänglichen Informationstafeln"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question4.13",
  "title": "Ausgemusterte Gerätschaften werden weitergegeben (z.B. an soziale Einrichtungen) nämlich:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Elektrogeräte"
    },
     {
      "value": "2",
      "text": "Matratzen"
    },
     {
      "value": "3",
      "text": "Handtücher"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}

 ] 
 },{ 
 "name": "page5", 
 "title": "TRANSPARENZ & BILDUNG", 
 "elements": [
{
  "type": "checkbox",
  "name": "question5.1",
  "title": "Wir haben eine Nachhaltigkeitsstrategie entwickelt und verfolgen diese aktiv, nämlich:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Unsere Unterkunft hat schriftlich festgehaltene Leitsätze"
    },
     {
      "value": "2",
      "text": "Unsere Unterkunft verfügt über eine Unternehmensphilosophie bzw. einen Unternehmenskodex, die/der unseren Ansatz in Bezug zur Nachhaltigkeit konkret formuliert, und umgesetzt wird"
    },
     {
      "value": "3",
      "text": "Schriftlich festgehalten sind Regularien zu mindestens 5 der folgenden Kriterien: umweltfreundliche, soziale, kulturelle, ökonomische, menschenrechtliche, gesundheitliche"
    },
     {
      "value": "4",
      "text": "Die Mitarbeitenden identifizieren sich mit den nachhaltigen Zielen"
    },
     {
      "value": "5",
      "text": "Die Führungsebene identifiziert sich mit den nachhaltigen Zielen"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question5.2",
  "title": "Wir bieten umfassende Einblicke in unser Nachhaltigkeitsmanagement, und legen offen welche Indikatoren zur Nachhaltigkeitsmessung genutzt werden",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.3",
  "title": "Wir kommunizieren die vorhandenen Defizite im Bereich Nachhaltigkeit offen gegenüber unseren Mitarbeitenden sowie Gästen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.4",
  "title": "Wir kommunizieren unser Nachhaltigkeitsengagement in Stellenausschreibungen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.5",
  "title": "Wir thematisieren Nachhaltigkeitsaspekte in unseren Teamsitzungen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.6",
  "title": "Mindestens einer unserer Angestellten ist ausgebildet, um über nachhaltiges Verhalten in der Umgebung zu informieren (z.B. giftige Stoffe für die Umwelt, Lärmbelästigung, Lichtverschmutzung, Respekt von Kultur, Fauna und Flora wie z.B. Nichterlaubnis des Fütterns von Tieren)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.7",
  "title": "Wir binden die Gäste durch folgende Maßnahmen aktiv in unser Nachhaltigkeitsmanagement ein:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Informationen und Tipps für einen umweltschonenden Aufenthalt wie z.B. Energie-Wasser- und Abfallreduktion"
    },
     {
      "value": "2",
      "text": "Tipps zu umweltschonender Ernährungsweise"
    },
     {
      "value": "3",
      "text": "Tipps zu umweltschonender An- und Abreise"
    },
     {
      "value": "4",
      "text": "Tipps zu umweltschonenden Ausflügen"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "boolean",
  "name": "question5.8",
  "title": "Wir erfragen Feedback von Gästen zum Thema Nachhaltigkeit aktiv und gehen darauf ein",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.9",
  "title": "Wir organisieren spezielle Events für Gäste und Mitarbeitende mit dem Thema Nachhaltigkeit (z.B. Aktion zum Weltumwelttag, Besuch von Lieferanten, ...)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.10",
  "title": "Wir teilen unser Wissen im Bereich Nachhaltigkeit mit anderen Hotels und Betrieben (mind. 1 weiterer Betrieb oder 1 Tourismusverband)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.11",
  "title": "Wir pflegen Kooperationen zu nachhaltigen Stakeholdern der Umgebung, darunter:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Rabatte bei regional-biologischen Restaurants für die Gäste"
    },
     {
      "value": "2",
      "text": "Rabatte für nachhaltige Ausflüge"
    },
     {
      "value": "3",
      "text": "Kooperationen mit lokalen NGOs"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question5.12",
  "title": "Wir pflegen Kontakt zu Anspruchsgruppen der Umgebung und setzen uns über die Unterkunftsgrenzen hinweg für Nachhaltigkeit ein (z.B. in der Gemeinde)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.13",
  "title": "Wir stellen unseren Umgang mit Daten der Mitarbeitenden sowie Gäste transparent dar",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.14",
  "title": "Unsere Mitarbeitergeschenke sind nachhaltig und nützlich",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page6", 
 "title": "SOZIALE NACHHALTIGKEIT", 
 "elements": [
{
  "type": "checkbox",
  "name": "question6.1",
  "title": "Menschen mit folgenden Einschränkungen können bei uns bequem nächtigen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Personen mit physischen Einschränkungen (z.B. Rollstuhl, Kinderwagen)"
    },
     {
      "value": "2",
      "text": "Seheinschränkungen"
    },
     {
      "value": "3",
      "text": "Gehöreinschränkungen"
    },
     {
      "value": "4",
      "text": "Geistiger Behinderung"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question6.2",
  "title": "Wir stellen unsere Informationen in Brailleschrift zur Verfügung (z.B. Ausschilderungen, Beschriftungen im Fahrstuhl, Bedienungshinweise im Zimmer)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.3",
  "title": "Der Anteil unserer barrierefreien Zimmer liegt bei ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.4",
  "title": "Bei uns ist mindestens ein barrierefreier Essensraum vorhanden",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.5",
  "title": "Bei uns ist mindestens ein barrierefreier Freizeitraum vorhanden",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.6",
  "title": "Unsere Außenanlage ist mindestens teilweise barrierefrei zugänglich",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.7",
  "title": "Wir kommunizieren unsere Maßnahmen für die Barrierefreiheit klar und leicht zugänglich nach außen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.8",
  "title": "Unsere Webseite ist barrierefrei gestaltet. (z.B. Nutzung von leichter Sprache/automatisiertes Vorlesen von Texten/Alternativtexte für Bilder)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.9",
  "title": "Wir bieten ...% unserer Mitarbeitenden feste Verträge an anstelle von Saisonarbeit oder Kurzzeitverträge",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.10",
  "title": "Wir verfolgen Maßnahmen, um die Sicherheit am Arbeitsplatz zu fördern",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.11",
  "title": "Wir achten bei Dienstplänen und Arbeitszeitmodellen auf die persönlichen Bedürfnisse unserer Mitarbeitenden (z.B. Familie, Pflegesituation, ...)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.12",
  "title": "Die Gleichverteilung der Geschlechter wird auf allen Führungsebenen angestrebt",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.13",
  "title": "Wir haben eine Richtlinie gegen Diskriminierung und Gewalt und leben diese aktiv",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.14",
  "title": "In der Außenrepräsentation und Werbung unserer Unterkunft stellen wir ein diverses Team dar (z.B. Bilder mit Menschen mit Behinderung, verschiedene Geschlechter, POC)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.15",
  "title": "Wir bieten allen Mitarbeitenden die Möglichkeit mindestens einmal pro Jahr an Schulungen zu Themen im Bereich Nachhaltigkeit teilzunehmen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.16",
  "title": "Wir bieten unseren Mitarbeitenden sonstige Aus- und Weiterbildungsmöglichkeiten an",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.17",
  "title": "Wir bieten ... Ausbildungsplätze pro Jahr an",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.18",
  "title": "Wir haben Maßnahmen, um die Gesundheit unserer Mitarbeitenden zu fördern (z.B. kostenfreie nachhaltige Getränke und Nahrungsmittel, kostenfreier Zugang zu Sport- und Gesundheitsprogrammen)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.19",
  "title": "Wir erleichtern unseren Mitarbeitenden die Anreise mit öffentlichen Verkehrsmitteln (z.B. durch Kostenreduktion/Shuttle von der nächstgelegenen Station)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.20",
  "title": "Der Anteil an Personen mit geistigen oder körperlichen Beeinträchtigungen an unseren Beschäftigten beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question6.21",
  "title": "Der Anteil an Mitarbeitenden, die mit Personen besetzt sind, die bereits in der Region wohnhaft waren, beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.22",
  "title": "Wir beziehen Mitarbeitende aktiv in Entscheidungsprozesse ein, holen Feedback ein und nutzen dieses",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.23",
  "title": "Wir betreiben ein aktiv genutztes Beschwerdemanagement für Mitarbeitende und leiten davon Personalstrategien ab",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.24",
  "title": "In unserer Unterkunft liegt der Faktor zwischen geringstem und höchstem Gehalt bei",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.25",
  "title": "Wir bezahlen keine Gehälter unter dem gesetzlichen Mindestlohn",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.26",
  "title": "Unsere Mitarbeitenden werden in Entscheidungsprozesse der Führungsebene eingebunden, die sie betreffen (z.B. Arbeitsschutz/Gesundheitsförderung/Arbeitszeiten)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.27",
  "title": "Wir ergreifen Maßnahmen gegen Kinderarbeit sowie Sextourismus",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.28",
  "title": "Wir spenden ...% unserer jährlichen Einnahmen an Vereine/NGOs/andere gemeinnützige Organisationen",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl sein",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.29",
  "title": "Im vergangenen Jahr haben wir uns außerordentlich für das Wohle der Gesellschaft eingesetzt mit einer der folgenden oder einer Maßnahme in vergleichbarem Ausmaß: zur Verfügung stellen von Wohnraum für Flüchtlinge, Einrichten sicherer Räume für von Gewalt bedrohten Menschen, Angebote ermäßigter Übernachtungskosten für gemeinnützige Gruppen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question6.30",
  "title": "Wir nutzen eine nachhaltige Bank:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "GLS Bank"
    },
     {
      "value": "2",
      "text": "Die Triodos Bank"
    },
     {
      "value": "3",
      "text": "Die Ethikbank"
    },
     {
      "value": "4",
      "text": "Tomorow"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine"
}

 ] 
 },{ 
 "name": "page7", 
 "title": "LEBENSMITTEL & PFLEGE", 
 "elements": [
{
  "type": "text",
  "name": "question7.1",
  "title": "Der Anteil der regionalen Produkte (<200 km) im Einkauf beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.2",
  "title": "Der Anteil an biologischen Produkten im Einkauf beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.3",
  "title": "Wir achten im Besonderen auf saisonale Ernährung",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question7.4",
  "title": "Der Anteil an veganen Gerichten bei der Bereitstellung von Hauptmahlzeiten sowie Buffets beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.5",
  "title": "Der Anteil an vegetarischen Gerichten bei der Bereitstellung von Hauptmahlzeiten sowie Buffets beträgt ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.6",
  "title": "Wir bieten die Möglichkeit, individuell auf Kundenbedürfnisse, wie z.B. Unverträglichkeiten einzugehen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.7",
  "title": "Wir klären über die Umweltfolgen verschiedener Produktkategorien auf (z.B. CO2-Fußabdruck, Wasser- und Energieverbrauch verschiedener Lebensmittel)",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.8",
  "title": "Wir klären über die Herkunft und die dort herrschenden Produktionsbedingungen der von uns verwendeten Waren auf",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question7.9",
  "title": "Bei von uns zum Verkauf angebotenen Snacks und Getränken achten wir auf folgende Kriterien:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Verpackungsarm"
    },
     {
      "value": "2",
      "text": "Vegan"
    },
     {
      "value": "3",
      "text": "Lokal"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.10",
  "title": "Wir minimieren Lebensmittelabfälle an allen Stellen der Liefer- und Produktionskette durch folgende Maßnahmen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Akkurate Einkaufsplanung"
    },
     {
      "value": "2",
      "text": "Ununterbrochene Kühlkette"
    },
     {
      "value": "3",
      "text": "Wiederverwertung überschüssiger Lebensmittel z.B. in alternativen Gerichten am nächsten Tag"
    },
     {
      "value": "4",
      "text": "Bereitstellung von Lebensmittelresten für einen reduzierten Preis direkt an Unterkunftsgäste"
    },
     {
      "value": "5",
      "text": "Nutzung der App Too good to go"
    },
     {
      "value": "6",
      "text": "Kooperation mit Foodsharing"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.11",
  "title": "Wir vermeiden Lebensmittelverpackungen nämlich:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Aufstriche (z.B. Nuss-Nougat-Creme, Honig, Marmelade, Gemüseaufstriche, Streichkäse) nicht in Kleinverpackungen"
    },
     {
      "value": "2",
      "text": "Salz, Pfeffer und Zucker in Streuern (keine Kleintüten)"
    },
     {
      "value": "3",
      "text": "Süßigkeitengläser mit Zangen statt Süßigkeitentüten"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.12",
  "title": "Wir vermeiden Getränkeverpackungen durch folgende Maßnahmen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Zapfanlagen für Säfte"
    },
     {
      "value": "2",
      "text": "Kaffeepulver anstelle von Kaffeekapseln"
    },
     {
      "value": "3",
      "text": "Loser Tee anstelle von Teebeuteln"
    },
     {
      "value": "4",
      "text": "Flaschenautomat mit Mehrwegflaschen"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.13",
  "title": "Wir wählen bereitgestellte Pflegeprodukte basierend auf Nachhaltigkeitskriterien aus, nämlich:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Verpackungsfrei, auffüllbare Verpackungen"
    },
     {
      "value": "2",
      "text": "Mikroplastikfrei, tierversuchsfrei, biologisch angebaute Rohstoffe, frei von Mineralölen"
    },
     {
      "value": "3",
      "text": "Fairtrade"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.14",
  "title": "Unsere Textilien, Handtücher, Bademantel und Badeschlappen (falls zutreffend) erfüllen folgende Kriterien:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Lokal"
    },
     {
      "value": "2",
      "text": "Frei von Kinderarbeit"
    },
     {
      "value": "3",
      "text": "Biologische Rohstoffe "
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question7.15",
  "title": "Wir verwenden ungebleichtes Recycling-Hygienepapier, das den Anforderungen folgenden Labels entspricht:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Blauer Engel"
    },
     {
      "value": "2",
      "text": "EU-Ecolabel"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}

 ] 
 },{ 
 "name": "page8", 
 "title": "ARCHITEKTUR & MATERIALIEN", 
 "elements": [
{
  "type": "boolean",
  "name": "question8.1",
  "title": "Beim Erwerb des Grundstücks der Unterkunft wurden Besitzrechte berücksichtigt",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question8.2",
  "title": "Beim Bau sowie Umbau der Unterkunft haben wir zu ...% regionale (<200km) Materialien verwendet",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Muss eine Zahl zwischen 0 und 100 sein",
  "min": 0,
  "max": 100
}
,{
  "type": "checkbox",
  "name": "question8.3",
  "title": "Beim Bau sowie Umbau der Unterkunft haben wir folgende Kriterien berücksichtigt:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Nachhaltigkeit in der Produktion der Materialien"
    },
     {
      "value": "2",
      "text": "Langlebigkeit der Materialien"
    },
     {
      "value": "3",
      "text": "Recyclingfähigkeit der Materialien"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question8.4",
  "title": "Bei der Entscheidung für die Architektur im Bau oder Umbau haben wir auf folgendes geachtet:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Energie- und Ressourcenminimierung im späteren Betrieb"
    },
     {
      "value": "2",
      "text": "Minimierung des Flächenverbrauchs"
    },
     {
      "value": "3",
      "text": "Energiesparende Architektur"
    },
     {
      "value": "4",
      "text": "Effizienter Einsatz von Materialien (Materialreduktion)"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "checkbox",
  "name": "question8.5",
  "title": "Wir halten die Gebäude auf dem aktuellen Stand der Technik in den Bereichen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Isolation"
    },
     {
      "value": "2",
      "text": "Mehrfachverglasung"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "checkbox",
  "name": "question8.6",
  "title": "Beim Bau sowie Umbau der Unterkunft werden Abfälle von Baumaterialien:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Im eigenen Haus wiederverwendet"
    },
     {
      "value": "2",
      "text": "Recycelt"
    },
     {
      "value": "3",
      "text": "Weitergegeben"
    },
     {
      "value": "4",
      "text": "Auf nachhaltige Art und Weise entsorgt"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Keine",
}
,{
  "type": "checkbox",
  "name": "question8.7",
  "title": "Wir achten bei der Ausstattung der Zimmer mit Möbeln und Dekoration auf folgende Nachhaltigkeitsaspekte:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Verwendung von regionalen Materialien (<200km)"
    },
     {
      "value": "2",
      "text": "Verwendung von zertifizierten Materialien, z.B. Plastikfrei/ zertifiziertes Holz (FSC)/ Biostoffe"
    },
     {
      "value": "3",
      "text": "Unterstützung lokaler Werkstätten, Künstler und sonstige Anbieter"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question8.8",
  "title": "Wir achten bei der Außenanlagengestaltung auf einheimische Pflanzen und naturgetreue Gartenarchitektur",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.9",
  "title": "Das Hotel bettet sich landschaftlich und kulturell in die Umgebung ein. Folgendes wird berücksichtigt:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Kulturerbe (sowohl Gebäude als auch immaterielles Kulturerbe, wie z.B. die Ausübung von Bräuchen und Sitten)"
    },
     {
      "value": "2",
      "text": "Naturerbe (Berücksichtigung von bedrohter Fauna und Flora), aktiver Naturschutz"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question8.10",
  "title": "Auswirkungen auf Flora und Fauna werden regelmäßig analysiert und Maßnahmen zu deren Schutz ergriffen",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.11",
  "title": "Wir unterstützen Fauna und Flora durch folgende Maßnahmen:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Anlage von Naturwiesen"
    },
     {
      "value": "2",
      "text": "Unterkünfte für Insekten z.B. Wildbienenhotel"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Keine",
  "otherText": "Sonstige"
}
,{
  "type": "boolean",
  "name": "question8.12",
  "title": "Beim Bau sowie Betrieb der Unterkunft bewahren wir kulturelle Aspekte der Umgebung bei und erhalten archäologische Städte (falls zutreffend) aufrecht",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question8.13",
  "title": "Wir berücksichtigen Risikofaktoren und führen präventive Maßnahmen zur Vermeidung von Umweltkatastrophen (falls zutreffend) durch",
  "defaultValue": "false",
  "labelTrue": "Ja",
  "labelFalse": "Nein",
  "isRequired": true
}

 ] 
 },]
}
 const defaultSurveyCSS = {   header: "bg-secondary text-white p-3" 
 }; 
const defaultSurveyDATA = {};
const defaultSurveyConfigDE = { 
   defaultSurveyCSS, 
   defaultSurveyDATA, 
   defaultSurveyJSONDE, 
}; 
export default defaultSurveyConfigDE;