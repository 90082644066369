const defaultSurveyJSONFR = { 
 "title": "Formulaire sur la durabilité", 
 "pages": [{ 
 "name": "page1", 
 "title": "DONNÉES DE BASE", 
 "elements": [
{
  "type": "text",
  "name": "accomodationToken",
  "title": "Jeton d'hébergemen",
  "isRequired": true,
  "requiredErrorText": "Le jeton d'hébergement peut être généré sur la page de profil de l'hébergement"
}
,{
  "type": "text",
  "name": "question1.2",
  "title": "Nombre d'employés à temps plein/partiel",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "dropdown",
  "name": "question1.3",
  "title": "Pays",
  "isRequired": true,
  "requiredErrorText": "",
  "choicesByUrl": {
    "url": "https://surveyjs.io/api/CountriesExample"
  }
}
,{
  "type": "text",
  "name": "question1.4",
  "title": "Nombre total de chambres",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.5",
  "title": "Nombre total de lits",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.6",
  "title": "Nombre total de nuits/an (année moyenne)",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}

 ] 
 },{ 
 "name": "page2", 
 "title": "MOBILITÉ", 
 "elements": [
{
  "type": "boolean",
  "name": "question2.1",
  "title": "Tous nos voyages d'affaires sont réservés et effectués de manière durable",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.2",
  "title": "Nous nous abstenons sciemment d'utiliser des voitures de société à moteur à combustion",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.3",
  "title": "Nos employés sont formés pour évaluer l'impact environnemental des différents moyens de transport et pour donner des conseils à ce sujet en ce qui concerne les déplacements entre le domicile et le lieu de travail ainsi que la mobilité sur le site",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.4",
  "title": "Nous fournissons à chaque invité un billet à prix réduit ou gratuit pour les services de transport locaux",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.5",
  "title": "Nous offrons des options de transport respectueuses de l'environnement, telles que la location de vélos, le covoiturage, les parcs relais",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question2.6",
  "title": "Nous disposons du nombre suivant de stations de recharge électrique sur le site :",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "<= 3"
    },
     {
      "value": "2",
      "text": "<=10"
    },
     {
      "value": "3",
      "text": "> 10"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun"
}
,{
  "type": "boolean",
  "name": "question2.7",
  "title": "Nos stations de recharge électronique sont compatibles avec plus d'un type de prise de recharge",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.8",
  "title": "Nous disposons d'au moins une station de charge avec une vitesse de charge >= 11kwH",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.9",
  "title": "Nous offrons le service de rechargement de la voiture au niveau d'énergie souhaité à toutes les personnes arrivant par e-mobile",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page3", 
 "title": "EAU ET ÉNERGIE", 
 "elements": [
{
  "type": "boolean",
  "name": "question3.1",
  "title": "Nous avons des experts dans notre entreprise qui connaissent la consommation d'énergie et d'eau ou nous obtenons une aide extérieure (par exemple, en Allemagne, subventionnée par l'Office fédéral de l'économie et du contrôle des exportations)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.2",
  "title": "Les équipements que nous utilisons (par exemple, les réfrigérateurs, les machines à laver, les téléviseurs) sont récents et appartiennent à la catégorie la plus économe en ressources (par exemple, en Allemagne, la classe d'efficacité A)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.3",
  "title": "Notre consommation d'électricité/nombre total de nuitées l'année dernière était de ...kWh",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.4",
  "title": "Nous utilisons des systèmes d'électricité et de chauffage autosuffisants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Solaire"
    },
     {
      "value": "2",
      "text": "Blocs d'énergie"
    },
     {
      "value": "3",
      "text": "Pompes à chaleur"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "text",
  "name": "question3.5",
  "title": "Notre consommation d'eau potable par nuitée l'année dernière était de ...L",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.6",
  "title": "Nous utilisons l'eau de pluie pour les applications suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Toilette"
    },
     {
      "value": "2",
      "text": "Douche"
    },
     {
      "value": "3",
      "text": "Irrigation"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question3.7",
  "title": "Nous prenons d'autres mesures pour réduire la consommation d'eau, à savoir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Transparence de la consommation d'eau et conseils de réduction aux invités"
    },
     {
      "value": "2",
      "text": "Utilisation de radiateurs en perles"
    },
     {
      "value": "3",
      "text": "Utilisation de pommeaux de douche économiques"
    },
     {
      "value": "4",
      "text": "Réparation des problèmes de compaction et de calcaire en moins de 24h"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question3.8",
  "title": "En matière d'approvisionnement en eau, nous veillons à ne pas restreindre les besoins locaux. Le logement ne contribue pas à la pénurie d'eau et à une augmentation significative des prix",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.9",
  "title": "Nous avons établi des indicateurs clés de performance et des procédures de contrôle et d'amélioration continue pour réduire la consommation d'eau et d'énergie",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.10",
  "title": "Nous réduisons la consommation d'énergie grâce à des offres d'utilisation partagée (par exemple, les appareils dans les parties communes (par exemple, un réfrigérateur partagé au lieu d'un minibar, une télévision partagée))",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.11",
  "title": "La part d'éclairage LED dans nos logements est ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question3.12",
  "title": "Nous minimisons la consommation d'électricité en utilisant des détecteurs de mouvement dans les zones peu fréquentées",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.13",
  "title": "Nous vérifions régulièrement (au moins une fois par an) l'efficacité des systèmes de chauffage et, le cas échéant, de climatisation et de ventilation et prenons des mesures si nécessaire",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question3.14",
  "title": "Nous utilisons les fournisseurs d'électricité suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Bürgerwerke"
    },
     {
      "value": "2",
      "text": "Elektrizitätswerke Schönau"
    },
     {
      "value": "3",
      "text": "Green City Power"
    },
     {
      "value": "4",
      "text": "Green Planet Energy"
    },
     {
      "value": "5",
      "text": "Grün.Power"
    },
     {
      "value": "6",
      "text": "Mann Strom"
    },
     {
      "value": "7",
      "text": "Naturstrom"
    },
     {
      "value": "8",
      "text": "Ökostrom+Polarstrom"
    },
     {
      "value": "9",
      "text": "Strom von Föhr"
    },
     {
      "value": "10",
      "text": "Fair Trade Power"
    },
     {
      "value": "11",
      "text": "Westfalenwind Strom"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun"
}

 ] 
 },{ 
 "name": "page4", 
 "title": "CONSOMMATION & DÉCHETS", 
 "elements": [
{
  "type": "text",
  "name": "question4.1",
  "title": "Notre consommation de déchets résiduels/de nuitée de l'année dernière était de ...L",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question4.2",
  "title": "Nous prenons des mesures pour réduire constamment la quantité de déchets, comme suit:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Pas d'utilisation de pailles jetables"
    },
     {
      "value": "2",
      "text": "Cadeaux et snacks non emballés (par exemple, biscuits en vrac, bocaux de noix au lieu de boîtes de conserve, boîte de bonbons avec pinces au lieu d'être emballée)"
    },
     {
      "value": "3",
      "text": "Bouteilles réutilisables dans la chambre d'hôtel"
    },
     {
      "value": "4",
      "text": "Stations de recharge au lieu de distributeurs de boissons jetables"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question4.3",
  "title": "Dans notre hébergement, il y a un tri cohérent des déchets dans les zones suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Cuisine"
    },
     {
      "value": "2",
      "text": "Chambres"
    },
     {
      "value": "3",
      "text": "Couloirs"
    },
     {
      "value": "4",
      "text": "Réception"
    },
     {
      "value": "5",
      "text": "Salons/salles de réunion "
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "radiogroup",
  "name": "question4.4",
  "title": "Dans les salles sans séparation des déchets, un tri ultérieur est effectué par le personnel:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Oui"
    },
     {
      "value": "2",
      "text": "Non"
    },
     {
      "value": "3",
      "text": "Le tri des déchets est systématique partout"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question4.5",
  "title": "Nous fournissons des informations sur la prévention des déchets et le traitement adéquat des déchets aux clients et aux employés",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question4.6",
  "title": "Nous documentons le tri des déchets et prenons des mesures pour l'améliorer si nécessaire",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.7",
  "title": "Afin de minimiser les dommages environnementaux causés par les déchets générés, nous prenons les mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Utilisation de détergents biodégradables, de produits de nettoyage et d'entretien sans microplastiques et autres plastiques."
    },
     {
      "value": "2",
      "text": "Filtrage des eaux usées en cas de dommage environnemental"
    },
     {
      "value": "3",
      "text": "Elimination des substances potentiellement dangereuses d'une manière adaptée au matériau et les rendre inoffensives"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "checkbox",
  "name": "question4.8",
  "title": "Nous réduisons la quantité de papier vierge utilisée grâce aux mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Utilisation de solutions numériques lorsque cela est possible"
    },
     {
      "value": "2",
      "text": "Utilisation de papier recyclé"
    },
     {
      "value": "3",
      "text": "Impression recto-verso lorsque cela est possible"
    },
     {
      "value": "4",
      "text": "Utilisation de papier imprimé ordinaire comme bloc-notes ou papier brouillon"
    },
     {
      "value": "5",
      "text": "Distribution occasionnelle de blocs-notes aux invités et aux employés"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "checkbox",
  "name": "question4.9",
  "title": "Pour la consommation de papier qui ne peut être réduite, nous utilisons:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Des modèles permettant d'économiser du papier"
    },
     {
      "value": "2",
      "text": "Des motifs économisant les couleurs"
    },
     {
      "value": "3",
      "text": "Des couleurs durables"
    },
     {
      "value": "4",
      "text": "Des imprimantes respectueuses de l'environnement pour les commandes"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "radiogroup",
  "name": "question4.10",
  "title": "Dans notre hébergement nous utilisons matériel jetable de manière que:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Nous ne l'utilisons jamais"
    },
     {
      "value": "2",
      "text": "Dans les évenements pendant lesquels nous utilisons matériel jetable, cet matériel est totalement compostables"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun"
}
,{
  "type": "boolean",
  "name": "question4.11",
  "title": "Nous réduisons les déchets liés à l'expédition en préférant les options d'expédition numériques aux options physiques",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.12",
  "title": "Nous imprimons les documents d'information uniquement lorsque cela est nécessaire. Alternativement, nous utilisons:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Des codes QR"
    },
     {
      "value": "2",
      "text": "Des informations sur le site web"
    },
     {
      "value": "3",
      "text": "Des informations sur les panneaux d'information généralement accessibles"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question4.13",
  "title": "Les équipements mis au rebut sont transmis (par exemple à des institutions sociales), à savoir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Appareils électriques"
    },
     {
      "value": "2",
      "text": "Matelas"
    },
     {
      "value": "3",
      "text": "Serviettes de bain"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}

 ] 
 },{ 
 "name": "page5", 
 "title": "TRANSPARENCE ET ÉDUCATION", 
 "elements": [
{
  "type": "checkbox",
  "name": "question5.1",
  "title": "Nous avons développé et poursuivons activement une stratégie de durabilité, à savoir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Notre établissement dispose de principes directeurs écrits"
    },
     {
      "value": "2",
      "text": "Notre hébergement dispose d'une philosophie d'entreprise ou d'un code de conduite, qui formule notre approche de la durabilité en termes concrets et que nous mettons en œuvre"
    },
     {
      "value": "3",
      "text": "Des règlements écrits sur au moins 5 des critères suivants : environnemental, social, culturel, économique, droits de l'homme, santé et sécurité"
    },
     {
      "value": "4",
      "text": "Les employés s'identifient aux objectifs de durabilité"
    },
     {
      "value": "5",
      "text": "La direction s'identifie aux objectifs de durabilité"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question5.2",
  "title": "Nous fournissons un aperçu complet de notre gestion de la durabilité et divulguons les indicateurs utilisés pour mesurer la durabilité",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.3",
  "title": "Nous communiquons ouvertement les déficits existants dans le domaine de la durabilité à nos employés et à nos invités",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.4",
  "title": "Nous communiquons notre engagement en faveur du développement durable dans les offres d'emploi",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.5",
  "title": "Nous abordons les thèmes de la durabilité lors des réunions d'équipe",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.6",
  "title": "Au moins un de nos employés est formé pour informer sur le comportement durable dans l'environnement (par exemple, les substances toxiques pour l'environnement, la pollution sonore, la pollution lumineuse, le respect de la culture, de la faune et de la flore, comme l'interdiction de nourrir les animaux)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.7",
  "title": "Nous impliquons activement les hôtes dans notre gestion de la durabilité, par les mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Informations et conseils pour un séjour respectueux de l'environnement, tels que la réduction de l'énergie, de l'eau et des déchets"
    },
     {
      "value": "2",
      "text": "Conseils pour une alimentation respectueuse de l'environnement"
    },
     {
      "value": "3",
      "text": "Conseils pour un voyage respectueux de l'environnement"
    },
     {
      "value": "4",
      "text": "Conseils pour des excursions respectueuses de l'environnement"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "boolean",
  "name": "question5.8",
  "title": "Nous demandons activement aux clients de nous faire part de leurs commentaires sur le thème de la durabilité et nous y répondons",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.9",
  "title": "Nous organisons des événements spéciaux pour les clients et les employés sur le thème de la durabilité (par exemple, action lors de la Journée mondiale de l'environnement, visite de fournisseurs, etc.)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.10",
  "title": "Nous partageons nos connaissances dans le domaine de la durabilité avec d'autres hôtels et entreprises (au moins une autre entreprise ou une association touristique)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.11",
  "title": "Nous entretenons des collaborations avec des acteurs de la durabilité dans la région, notamment:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Des réductions dans des restaurants biologiques régionaux pour les clients"
    },
     {
      "value": "2",
      "text": "Des réductions pour des excursions durables"
    },
     {
      "value": "3",
      "text": "Des coopérations avec des ONG locales"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question5.12",
  "title": "Nous maintenons le contact avec les parties prenantes dans la région environnante et défendons la durabilité au-delà des frontières de l'hébergement (par exemple, dans la communauté)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.13",
  "title": "Nous sommes transparents dans la manière dont nous traitons les données de nos employés et de nos hôtes",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.14",
  "title": "Nos cadeaux aux employés sont durables et utiles",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page6", 
 "title": "DURABILITÉ SOCIALE", 
 "elements": [
{
  "type": "checkbox",
  "name": "question6.1",
  "title": "Les personnes ayant les limitations suivantes peuvent séjourner chez nous avec succès:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Personnes ayant des limitations physiques (par exemple, fauteuil roulant, poussette)"
    },
     {
      "value": "2",
      "text": "Déficiences visuelles"
    },
     {
      "value": "3",
      "text": "Déficiences auditives"
    },
     {
      "value": "4",
      "text": "Handicap mental"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question6.2",
  "title": "Nous fournissons nos informations en braille (par exemple, les panneaux, les étiquettes dans l'ascenseur, les instructions d'utilisation dans la chambre)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.3",
  "title": "Le pourcentage de nos chambres sans obstacles est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.4",
  "title": "Au moins une salle à manger sans obstacle est disponible dans notre établissement",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.5",
  "title": "Au moins une salle de récréation accessible est disponible dans notre établissement",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.6",
  "title": "Nos installations extérieures sont au moins partiellement accessibles aux personnes handicapées",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.7",
  "title": "Nous communiquons nos mesures d'accessibilité de manière claire et facilement accessible au monde extérieur",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.8",
  "title": "Notre site web est conçu pour être accessible. (par exemple, utilisation d'un langage simple/lecture automatique à haute voix du texte/texte alternatif pour les images)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.9",
  "title": "Nous proposons à ...% de nos employés des contrats à durée indéterminée au lieu de des contrats saisonniers ou à court terme",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.10",
  "title": "Nous prenons des mesures pour promouvoir la sécurité sur le lieu de travail",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.11",
  "title": "Nous sommes attentifs aux besoins personnels de nos employés (par exemple, famille, soins, ...) en ce qui concerne les horaires de travail et les modèles de temps de travail",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.12",
  "title": "La proportion de femmes et d'hommes dans les postes de direction correspond à la proportion respective de tous les employés",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.13",
  "title": "Nous disposons d'une politique de lutte contre la discrimination et la violence et nous la mettons en œuvre activement",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.14",
  "title": "Dans la représentation externe et la publicité de notre logement, nous présentons une équipe diversifiée (par exemple, des photos de personnes handicapées, de personnes de sexe différent, de personnes d'origine différente)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.15",
  "title": "Nous offrons à tous les employés la possibilité de participer à une formation sur des sujets liés au développement durable au moins une fois par an",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.16",
  "title": "Nous offrons d'autres possibilités de formation et de développement à nos employés",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.17",
  "title": "Nous offrons ... postes de formation par an",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.18",
  "title": "Nous avons mis en place des mesures pour promouvoir la santé de nos employés (par exemple, boissons et aliments durables gratuits, accès gratuit à des programmes sportifs et de santé)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.19",
  "title": "Nous facilitons les déplacements de nos employés par les transports en commun (par exemple, en réduisant les coûts/en faisant des navettes depuis la station la plus proche)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.20",
  "title": "La proportion de personnes souffrant d'un handicap mental ou physique dans notre effectif est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question6.21",
  "title": "Le pourcentage d'employés recrutés par des personnes qui étaient déjà des résidents de la région est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.22",
  "title": "Nous impliquons activement les employés dans les processus de prise de décision, nous sollicitons des commentaires et nous les utilisons",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.23",
  "title": "Nous utilisons activement les mécanismes de réclamation des employés et en tirons des stratégies de ressources humaines",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.24",
  "title": "Dans notre logement, le facteur entre le salaire le plus bas et le plus élevé est",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.25",
  "title": "Nous ne versons pas de salaires inférieurs au salaire minimum légal",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.26",
  "title": "Nos employés sont impliqués dans les processus de décision de la direction qui les concernent (par exemple, la santé et la sécurité au travail/la promotion de la santé/hours de travail)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.27",
  "title": "Nous prenons des mesures contre le travail des enfants et le tourisme sexuel",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.28",
  "title": "Nous reversons ...% de nos revenus annuels à des associations/ONG/autres organisations à but non lucratif",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Veuillez écrire un nombre",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.29",
  "title": "Au cours de l'année écoulée, nous avons fait preuve d'un engagement exceptionnel pour le bien de la société en prenant l'une des mesures suivantes ou une mesure d'une ampleur comparable : mise à disposition d'un logement pour les réfugiés, aménagement d'espaces sûrs pour les personnes menacées de violence, offre de frais d'hébergement réduits pour les groupes à but non lucratif",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question6.30",
  "title": "Nous utilisons une banque durable:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "GLS Bank"
    },
     {
      "value": "2",
      "text": "The Triodos Bank"
    },
     {
      "value": "3",
      "text": "The Ethikbank"
    },
     {
      "value": "4",
      "text": "Tomorow"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun"
}

 ] 
 },{ 
 "name": "page7", 
 "title": "ALIMENTATION ET HYGIÈNE", 
 "elements": [
{
  "type": "text",
  "name": "question7.1",
  "title": "La part des produits régionaux (<200 km) dans les achats est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.2",
  "title": "La part des produits biologiques dans les achats est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.3",
  "title": "Nous accordons une attention particulière aux aliments de saison",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question7.4",
  "title": "La proportion de plats végétaliens dans les repas principaux et les buffets est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.5",
  "title": "La proportion de plats végétariens dans les repas principaux et les buffets est de ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.6",
  "title": "Nous offrons la possibilité de répondre individuellement aux besoins des clients, tels que les intolérances",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.7",
  "title": "Nous informons sur l'impact environnemental des différentes catégories de produits (par exemple, l'empreinte carbone, la consommation d'eau et d'énergie des différents aliments)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.8",
  "title": "Nous informons sur l'origine et les conditions de production des produits que nous utilisons",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question7.9",
  "title": "Nous sommes attentifs aux critères suivants pour les snacks et les boissons que nous proposons à la vente:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Faible niveau d'emballage"
    },
     {
      "value": "2",
      "text": "Végétalien"
    },
     {
      "value": "3",
      "text": "Local"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.10",
  "title": "Nous minimisons le gaspillage alimentaire à tous les points de la chaîne d'approvisionnement et de production grâce aux mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Une planification précise des achats"
    },
     {
      "value": "2",
      "text": "Une chaîne du froid ininterrompue"
    },
     {
      "value": "3",
      "text": "Recyclage des aliments excédentaires, par exemple dans des plats alternatifs le jour suivant"
    },
     {
      "value": "4",
      "text": "Fourniture des restes de nourriture à prix réduit directement aux clients de l'hébergement"
    },
     {
      "value": "5",
      "text": "Utilisation de l'application Too good to go"
    },
     {
      "value": "6",
      "text": "Coopération avec Foodsharing"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.11",
  "title": "Nous évitons les emballages alimentaires à savoir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Les pâtes à tartiner (par ex. crème de nougat aux noix, miel, confiture, légumes à tartiner, fromage à tartiner) pas en petits paquets"
    },
     {
      "value": "2",
      "text": "Le sel, le poivre et le sucre en salière (pas en petits sachets)"
    },
     {
      "value": "3",
      "text": "Pots de bonbons avec des pinces au lieu de sacs de bonbons"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.12",
  "title": "Nous évitons les emballages de boissons grâce aux mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Distributeurs de jus de fruits"
    },
     {
      "value": "2",
      "text": "Café en poudre au lieu de capsules de café"
    },
     {
      "value": "3",
      "text": "Thé en vrac au lieu de sachets de thé"
    },
     {
      "value": "4",
      "text": "Distributeur de bouteilles avec bouteilles consignées"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.13",
  "title": "Nous sélectionnons les produits d'entretien fournis en fonction de critères de durabilité, à savoir:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Sans emballage, emballage rechargeable"
    },
     {
      "value": "2",
      "text": "Sans microplastique, sans test sur les animaux, sans matières premières biologiques, sans huile minérale"
    },
     {
      "value": "3",
      "text": "Commerce équitable"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.14",
  "title": "Nos textiles, serviettes, peignoir de bain et chaussons (le cas échéant) répondent aux critères suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Local"
    },
     {
      "value": "2",
      "text": "Sans travail des enfants"
    },
     {
      "value": "3",
      "text": "Matières premières biologiques"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question7.15",
  "title": "Nous utilisons du papier recyclé non blanchi qui répond aux exigences des labels suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Ange bleu"
    },
     {
      "value": "2",
      "text": "Écolabel européen"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}

 ] 
 },{ 
 "name": "page8", 
 "title": "ARCHITECTURE ET MATÉRIAUX", 
 "elements": [
{
  "type": "boolean",
  "name": "question8.1",
  "title": "Les droits de possession ont été pris en compte lors de l'acquisition du terrain de l'hébergement",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question8.2",
  "title": "Pour la construction et la reconstruction du logement, nous avons utilisé ... % de matériaux régionaux (<200km)",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Doit être un nombre entre 0 et 100",
  "min": 0,
  "max": 100
}
,{
  "type": "checkbox",
  "name": "question8.3",
  "title": "Lors de la construction ainsi que de la reconstruction du logement nous avons pris en compte les critères suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Durabilité dans la production des matériaux"
    },
     {
      "value": "2",
      "text": "Durabilité des matériaux"
    },
     {
      "value": "3",
      "text": "Recyclabilité des matériaux"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question8.4",
  "title": "Lors du choix de l'architecture dans la construction ou la reconstruction, nous avons fait attention aux critères suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Minimisation de l'énergie et des ressources dans l'exploitation ultérieure"
    },
     {
      "value": "2",
      "text": "Minimisation de la consommation de terrain"
    },
     {
      "value": "3",
      "text": "Architecture économe en énergie"
    },
     {
      "value": "4",
      "text": "Utilisation efficace des matériaux (réduction des matériaux)"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "checkbox",
  "name": "question8.5",
  "title": "Nous maintenons les bâtiments à l'état actuel de la technique dans les domaines suivants:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Isolation"
    },
     {
      "value": "2",
      "text": "Vitrage multiple"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "checkbox",
  "name": "question8.6",
  "title": "Lors de la construction ainsi que de la reconstruction des logements, les déchets de matériaux de construction sont:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Réutilisés dans notre propre maison"
    },
     {
      "value": "2",
      "text": "Recyclés"
    },
     {
      "value": "3",
      "text": "Transmis"
    },
     {
      "value": "4",
      "text": "Éliminés de manière durable"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "Aucun",
}
,{
  "type": "checkbox",
  "name": "question8.7",
  "title": "Nous faisons attention aux aspects de durabilité suivants lors de l'aménagement des pièces avec le mobilier et la décoration:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Utilisation de matériaux régionaux (<200km)"
    },
     {
      "value": "2",
      "text": "Utilisation de matériaux certifiés, par exemple sans plastique/ bois certifié (FSC)/ matériaux biologiques"
    },
     {
      "value": "3",
      "text": "Soutien des ateliers, artistes et autres fournisseurs locaux"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question8.8",
  "title": "Nous prêtons attention aux plantes indigènes et à l'architecture naturelle des jardins lors de la conception des installations extérieures",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.9",
  "title": "L'hôtel s'intègre dans le paysage et la culture environnants. Les éléments suivants seront pris en compte:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Le patrimoine culturel (tant les bâtiments que le patrimoine culturel immatériel, comme la pratique des coutumes et des traditions)"
    },
     {
      "value": "2",
      "text": "Le patrimoine naturel (prise en compte de la faune et de la flore menacées), la conservation active de la nature"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question8.10",
  "title": "Les impacts sur la faune et la flore sont régulièrement analysés et des mesures sont prises pour leur protection",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.11",
  "title": "Nous soutenons la faune et la flore par les mesures suivantes:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Création de prairies naturelles"
    },
     {
      "value": "2",
      "text": "Création d'abris pour les insectes, par exemple un hôtel pour abeilles sauvages"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "Aucun",
  "otherText": "Autre"
}
,{
  "type": "boolean",
  "name": "question8.12",
  "title": "Pendant la construction ainsi que l'exploitation de l'abri, nous préservons les aspects culturels de l'environnement et maintenons les cités archéologiques (le cas échéant)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question8.13",
  "title": "Nous tenons compte des facteurs de risque et mettons en œuvre des mesures préventives pour éviter les catastrophes environnementales (le cas échéant)",
  "defaultValue": "false",
  "labelTrue": "Oui",
  "labelFalse": "Non",
  "isRequired": true
}

 ] 
 },]
}
 const defaultSurveyCSS = {   header: "bg-secondary text-white p-3" 
 }; 
const defaultSurveyDATA = {};
const defaultSurveyConfigFR = { 
   defaultSurveyCSS, 
   defaultSurveyDATA, 
   defaultSurveyJSONFR, 
}; 
export default defaultSurveyConfigFR;