const defaultSurveyJSONEN = { 
 "title": "Sustainability survey", 
 "pages": [{ 
 "name": "page1", 
 "title": "BASIC DATA", 
 "elements": [
{
  "type": "text",
  "name": "accomodationToken",
  "title": "Accomodation Token",
  "isRequired": true,
  "requiredErrorText": "Accommodation token can be generated on the accommodation profile page"
}
,{
  "type": "text",
  "name": "question1.2",
  "title": "Number of employees full-time/part-time",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "dropdown",
  "name": "question1.3",
  "title": "Country",
  "isRequired": true,
  "requiredErrorText": "",
  "choicesByUrl": {
    "url": "https://surveyjs.io/api/CountriesExample"
  }
}
,{
  "type": "text",
  "name": "question1.4",
  "title": "Total number of rooms",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.5",
  "title": "Total number of beds",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "text",
  "name": "question1.6",
  "title": "Total nights per year (average year)",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}

 ] 
 },{ 
 "name": "page2", 
 "title": "MOBILITY", 
 "elements": [
{
  "type": "boolean",
  "name": "question2.1",
  "title": "All of our own business travel is booked and conducted sustainably",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.2",
  "title": "We consciously refrain from using company cars with combustion engines",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.3",
  "title": "Our employees are trained to assess the environmental impact of various transportation options and provide guidance on commuting to and from work and on-site mobility",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.4",
  "title": "We provide each guest with a reduced or free ticket for local transportation services",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.5",
  "title": "We offer environmentally friendly transportation options, such as bike rentals, car sharing, park & ride lots",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question2.6",
  "title": "We have the following number of e-charging stations on site:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "<= 3"
    },
     {
      "value": "2",
      "text": "<=10"
    },
     {
      "value": "3",
      "text": "> 10"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "None"
}
,{
  "type": "boolean",
  "name": "question2.7",
  "title": "Our e-charging stations are compatible with more than one type of charging plug",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.8",
  "title": "We have at least one charging station with a charging speed of more than 11kwH",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question2.9",
  "title": "We offer the service of charging the car to all those arriving by e-vehicle",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page3", 
 "title": "WATER & ENERGY", 
 "elements": [
{
  "type": "boolean",
  "name": "question3.1",
  "title": "We have experts in our company who know about energy and water consumption or we get external help (e.g. in Germany subsidized by the Federal Office of Economics and Export Control)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.2",
  "title": "The equipment we use (e.g. refrigerators, washing machines, TVs) is up to date and belongs to the most resource-efficient category (e.g. in the EU efficiency class A)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.3",
  "title": "Our electricity consumption per overnight stay last year was ...kWh",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.4",
  "title": "We use self-sufficient electricity and heating systems:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Solar"
    },
     {
      "value": "2",
      "text": "Block power"
    },
     {
      "value": "3",
      "text": "Heat pumps"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "text",
  "name": "question3.5",
  "title": "Our drinking water consumption per overnight stay last year was ...L",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question3.6",
  "title": "We use rainwater for the following applications:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Toilet"
    },
     {
      "value": "2",
      "text": "Shower"
    },
     {
      "value": "3",
      "text": "Irrigation"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question3.7",
  "title": "We take other measures to reduce water consumption, namely the following:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Transparency of water consumption and tips for reduction to guests"
    },
     {
      "value": "2",
      "text": "Use of pearl radiators"
    },
     {
      "value": "3",
      "text": "Use of economy shower heads"
    },
     {
      "value": "4",
      "text": "Repairing compaction and lime problems in under 24h"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question3.8",
  "title": "Regarding water supply, we do not restrict local needs. The accommodation does not contribute to water scarcity and significant price increase",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.9",
  "title": "We have established KPIs and procedures for continuous control and improvement to reduce water and energy consumption",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.10",
  "title": "We reduce energy consumption through shared use offerings (e.g. appliances in common areas (e.g. shared refrigerator instead of minibar, shared TV))",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question3.11",
  "title": "The share of LED lighting in our accommodation is ... %",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question3.12",
  "title": "We minimize energy consumption for electricity by using motion detectors in short visited areas",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question3.13",
  "title": "We regularly (at least once a year) check the efficiency of the heating and, if applicable, air conditioning and ventilation systems and take measures if necessary",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question3.14",
  "title": "We use the following electricity provider:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Bürgerwerke"
    },
     {
      "value": "2",
      "text": "Elektrizitätswerke Schönau"
    },
     {
      "value": "3",
      "text": "Green City Power"
    },
     {
      "value": "4",
      "text": "Green Planet Energy"
    },
     {
      "value": "5",
      "text": "Grün.Power"
    },
     {
      "value": "6",
      "text": "Mann Strom"
    },
     {
      "value": "7",
      "text": "Naturstrom"
    },
     {
      "value": "8",
      "text": "Ökostrom+Polarstrom"
    },
     {
      "value": "9",
      "text": "Strom von Föhr"
    },
     {
      "value": "10",
      "text": "Fair Trade Power"
    },
     {
      "value": "11",
      "text": "Westfalenwind Strom"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "None"
}

 ] 
 },{ 
 "name": "page4", 
 "title": "CONSUMPTION & WASTE", 
 "elements": [
{
  "type": "text",
  "name": "question4.1",
  "title": "Our consumed amount of residual waste per night and guest was ...L last year",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "checkbox",
  "name": "question4.2",
  "title": "We take measures to constantly reduce the amount of waste, as follows:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "No use of disposable straws"
    },
     {
      "value": "2",
      "text": "Unpackaged giveaways and snacks (e.g. loose cookies, nut jars instead of cans, candy box with tongs instead of wrapped)"
    },
     {
      "value": "3",
      "text": "Reusable bottles in the hotel room"
    },
     {
      "value": "4",
      "text": "Refill stations instead of disposable beverage machines"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question4.3",
  "title": "In our accommodation, there is a consistent waste separation in the following areas:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Kitchen"
    },
     {
      "value": "2",
      "text": "Rooms"
    },
     {
      "value": "3",
      "text": "corridors"
    },
     {
      "value": "4",
      "text": "Reception"
    },
     {
      "value": "5",
      "text": "lounges/meeting rooms "
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "radiogroup",
  "name": "question4.4",
  "title": "In rooms without waste separation, a subsequent sorting is carried out by the staff:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Yes"
    },
     {
      "value": "2",
      "text": "No"
    },
     {
      "value": "3",
      "text": "We have consistent waste separation everywhere"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question4.5",
  "title": "We provide information on waste avoidance and the adequate handling of waste to guests and employees",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question4.6",
  "title": "We document the waste separation and take measures to improve it if necessary",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.7",
  "title": "In order to minimize the environmental damage caused by the waste generated, we take the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Use of biodegradable detergents, cleaning, and care products without microplastics and other plastics"
    },
     {
      "value": "2",
      "text": "Filtering of wastewater in case of environmental damage"
    },
     {
      "value": "3",
      "text": "Disposing of potentially harmful substances in a manner appropriate to the material and rendering them harmless"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "checkbox",
  "name": "question4.8",
  "title": "We reduce the amount of virgin paper used through the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Use of digital solutions where possible"
    },
     {
      "value": "2",
      "text": "Use of recycled paper"
    },
     {
      "value": "3",
      "text": "Double-sided printing where possible"
    },
     {
      "value": "4",
      "text": "Use of plain printed paper as notepads and, or scratch paper"
    },
     {
      "value": "5",
      "text": "Sparingly handing out notepads to guests and employees"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "checkbox",
  "name": "question4.9",
  "title": "For paper consumption that cannot be reduced, we use:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Paper saving designs"
    },
     {
      "value": "2",
      "text": "Color saving designs"
    },
     {
      "value": "3",
      "text": "Sustainable colors"
    },
     {
      "value": "4",
      "text": "Environmentally friendly printers for orders"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "radiogroup",
  "name": "question4.10",
  "title": "In our company we handle disposable materials as follows:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "We entirely dispense with disposable materials"
    },
     {
      "value": "2",
      "text": "In case we use disposable materials, they are made of compostable materials exclusively"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "None"
}
,{
  "type": "boolean",
  "name": "question4.11",
  "title": "We reduce waste materials in shipping by preferring digital shipping options instead of physical ones",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question4.12",
  "title": "We print info materials only where necessary. Alternatively, we use:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "QR Codes"
    },
     {
      "value": "2",
      "text": "Information on our website"
    },
     {
      "value": "3",
      "text": "Information on generally accessible information boards"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question4.13",
  "title": "Discarded equipment is passed on (e.g. to social institutions) namely:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Electrical appliances"
    },
     {
      "value": "2",
      "text": "Mattresses"
    },
     {
      "value": "3",
      "text": "Towels"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}

 ] 
 },{ 
 "name": "page5", 
 "title": "TRANSPARENCY & EDUCATION", 
 "elements": [
{
  "type": "checkbox",
  "name": "question5.1",
  "title": "We have developed and actively pursue a sustainability strategy, namely:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Our accommodation has written guiding principles"
    },
     {
      "value": "2",
      "text": "Our accommodation has a corporate philosophy or code of conduct, which formulates our approach to sustainability in concrete terms, and is implemented by us"
    },
     {
      "value": "3",
      "text": "Written regulations on at least 5 of the following criteria: environmental, social, cultural, economic, human rights, health and safety"
    },
     {
      "value": "4",
      "text": "The employees identify with the sustainable goals"
    },
     {
      "value": "5",
      "text": "The management identifies with the sustainable goals"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question5.2",
  "title": "We provide comprehensive insights into our sustainability management and disclose which indicators are used to measure sustainability",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.3",
  "title": "We openly communicate the existing deficits in the area of sustainability to our employees as well as guests",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.4",
  "title": "We communicate our commitment to sustainability in job advertisements",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.5",
  "title": "We address sustainability topics in our team meetings",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.6",
  "title": "At least one of our employees is trained to inform about sustainable behavior in the environment, (e.g. toxic substances to the environment, noise pollution, light pollution, respect of culture, fauna and flora such as not allowing the feeding of animals)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.7",
  "title": "We actively involve guests in our sustainability management, through the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Information and tips for an environmentally friendly stay, such as energy-water and waste reduction"
    },
     {
      "value": "2",
      "text": "Tips for environmentally friendly nutrition"
    },
     {
      "value": "3",
      "text": "Tips for environmentally friendly transportation"
    },
     {
      "value": "4",
      "text": "Tips for environmentally friendly excursions"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "boolean",
  "name": "question5.8",
  "title": "We actively ask for and respond to feedback from guests on the topic of sustainability",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.9",
  "title": "We organize special events for guests and employees with the topic of sustainability (e.g. action on World Environment Day, visit of suppliers, ...)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.10",
  "title": "We share our knowledge in the field of sustainability with other hotels and businesses (at least 1 other business or 1 tourism association)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question5.11",
  "title": "We maintain collaborations with sustainable stakeholders in the area, including:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Discounts at regional organic restaurants for guests"
    },
     {
      "value": "2",
      "text": "Discounts for sustainable excursions"
    },
     {
      "value": "3",
      "text": "Cooperations with local NGOs"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question5.12",
  "title": "We maintain contact with stakeholders in the surrounding area and advocate for sustainability across accommodation boundaries (e.g. in the community)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.13",
  "title": "We are transparent in the way we handle data of our employees and guests",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question5.14",
  "title": "Our employee gifts are sustainable and useful",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}

 ] 
 },{ 
 "name": "page6", 
 "title": "SOCIAL SUSTAINABILITY", 
 "elements": [
{
  "type": "checkbox",
  "name": "question6.1",
  "title": "People with the following limitations can stay with us comfortably:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "People with physical limitations (e.g. wheelchair, stroller)"
    },
     {
      "value": "2",
      "text": "Visual impairments"
    },
     {
      "value": "3",
      "text": "Hearing impairments"
    },
     {
      "value": "4",
      "text": "Mental handicap"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question6.2",
  "title": "We provide our information in Braille (e.g. signs, labels in the elevator, operating instructions in the room)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.3",
  "title": "The percentage of our barrier-free rooms is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.4",
  "title": "At least one barrier-free dining room is available at our facility",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.5",
  "title": "At least one accessible recreation room is available at our facility",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.6",
  "title": "Our outdoor facilities are at least partially accessible to people with disabilities",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.7",
  "title": "We communicate our accessibility measures clearly and easily accessible to the outside world",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.8",
  "title": "Our website is designed to be accessible. (e.g. use of easy language/automated reading aloud of text/alternative text for images)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.9",
  "title": "We offer ...% of our employees permanent contracts instead of seasona or short-term contracts",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.10",
  "title": "We pursue measures to promote safety in the workplace",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.11",
  "title": "We pay attention to the personal needs of our employees (e.g. family, care situation, ...) when it comes to duty rosters and working time models",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.12",
  "title": "The proportion of women and men in management positions corresponds to the respective proportion of all employees",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.13",
  "title": "We have a policy against discrimination and violence and live it actively",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.14",
  "title": "In the external representation and advertising of our accommodation, we present a diverse team (e.g. pictures with people with disabilities, different genders, POC)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.15",
  "title": "We offer all employees the opportunity to participate in training on sustainability topics at least once a year",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.16",
  "title": "We offer other training and development opportunities to our employees",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.17",
  "title": "We offer ... trainee positions per year",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.18",
  "title": "We have measures in place to promote the health of our employees (e.g. free sustainable beverages and food, free access to sports and health programs)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.19",
  "title": "We make it easier for our employees to travel by public transportation (e.g. by reducing costs/shuttling from the nearest station)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.20",
  "title": "The proportion of people with mental or physical impairments in our workforce is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question6.21",
  "title": "The percentage of employees staffed by individuals who were already residents of the region is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question6.22",
  "title": "We actively involve employees in decision-making processes, solicit feedback and make use of it",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.23",
  "title": "We operate actively used employee grievance mechanisms and derive human resources strategies from them",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.24",
  "title": "In our accommodation, the factor between the lowest and highest salary is",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.25",
  "title": "We do not pay salaries below the legal minimum wage",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.26",
  "title": "Our employees are involved in management decision-making processes that affect them (e.g. occupational health and safety, working hours)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question6.27",
  "title": "We take measures against child labor as well as sex tourism",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question6.28",
  "title": "We donate ...% of our annual revenue to associations/NGOs/other non-profit organizations",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number",
  "min": 0,
  "max": 999999
}
,{
  "type": "boolean",
  "name": "question6.29",
  "title": "In the past year, we have made an extraordinary commitment to the good of society with one of the following or a comparable measure: providing housing for refugees, setting up safe spaces for people at risk of violence, offering reduced accommodation costs for non-profit groups",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "radiogroup",
  "name": "question6.30",
  "title": "We use a sustainable bank:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "GLS Bank"
    },
     {
      "value": "2",
      "text": "The Triodos Bank"
    },
     {
      "value": "3",
      "text": "The Ethikbank"
    },
     {
      "value": "4",
      "text": "Tomorow"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "hasOther": true,
  "otherPlaceHolder": "...",
  "noneText": "None"
}

 ] 
 },{ 
 "name": "page7", 
 "title": "FOOD & SANITATION", 
 "elements": [
{
  "type": "text",
  "name": "question7.1",
  "title": "The share of regional products (<200 km) in purchasing is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.2",
  "title": "The share of organic products in purchasing is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.3",
  "title": "We pay special attention to serving seasonal food",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question7.4",
  "title": "The proportion of vegan dishes in the provision of main meals as well as buffets is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "text",
  "name": "question7.5",
  "title": "The proportion of vegetarian dishes in the provision of main meals and buffets is ...%",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "boolean",
  "name": "question7.6",
  "title": "We offer the possibility to cater individually to customer needs, such as intolerances",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.7",
  "title": "We educate about the environmental impact of different product categories (e.g. carbon footprint, water and energy consumption of different foods)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question7.8",
  "title": "We educate about the origin and prevailing production conditions of the goods we use",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question7.9",
  "title": "We pay attention to the following criteria for snacks and beverages we offer for sale:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Low packaging"
    },
     {
      "value": "2",
      "text": "Vegan"
    },
     {
      "value": "3",
      "text": "Local"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.10",
  "title": "We minimize food waste at all points in the supply and production chain through the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Accurate purchasing planning"
    },
     {
      "value": "2",
      "text": "Uninterrupted cold chain"
    },
     {
      "value": "3",
      "text": "Recycling of surplus food e.g. in alternative dishes the next day"
    },
     {
      "value": "4",
      "text": "Provision of leftover food for a reduced price directly to accommodation guests"
    },
     {
      "value": "5",
      "text": "Use of the app Too good to go"
    },
     {
      "value": "6",
      "text": "Cooperation with Foodsharing"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.11",
  "title": "We avoid food packaging namely:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Spreads (e.g. nut nougat cream, honey, jam, vegetable spreads, cheese spreads) not in small packages"
    },
     {
      "value": "2",
      "text": "Salt, pepper and sugar in shakers (not small bags)"
    },
     {
      "value": "3",
      "text": "Candy jars with tongs instead of candy bags"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.12",
  "title": "We avoid beverage packaging through the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Dispensers for juices"
    },
     {
      "value": "2",
      "text": "Coffee powder instead of coffee capsules"
    },
     {
      "value": "3",
      "text": "Loose tea instead of tea bags"
    },
     {
      "value": "4",
      "text": "Bottle vending machine with returnable bottles"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.13",
  "title": "We select provided care products based on sustainability criteria, namely:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Packaging free, refillable packaging"
    },
     {
      "value": "2",
      "text": "Microplastic free, animal testing free, organic raw materials, mineral oil free"
    },
     {
      "value": "3",
      "text": "Fairtrade"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.14",
  "title": "Our textiles, towels, bath robe and slippers (if applicable) meet the following criteria:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Local"
    },
     {
      "value": "2",
      "text": "Free from child labor"
    },
     {
      "value": "3",
      "text": "Organic raw materials"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question7.15",
  "title": "We use unbleached recycled paper that meets the requirements of the following labels:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Blue Angel"
    },
     {
      "value": "2",
      "text": "EU-Ecolabel"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}

 ] 
 },{ 
 "name": "page8", 
 "title": "ARCHITECTURE & MATERIALS", 
 "elements": [
{
  "type": "boolean",
  "name": "question8.1",
  "title": "Possession rights were taken into account when acquiring the land of accommodation",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "text",
  "name": "question8.2",
  "title": "In the construction as well as reconstruction of the accommodation we used ...% regional (<200km) materials",
  "isRequired": true,
  "inputType": "number",
  "requiredErrorText": "Has to be a number between 0 and 100",
  "min": 0,
  "max": 100
}
,{
  "type": "checkbox",
  "name": "question8.3",
  "title": "During the construction as well as reconstruction of the accommodation we took into account the following criteria:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Sustainability in the production of materials"
    },
     {
      "value": "2",
      "text": "Durability of the materials"
    },
     {
      "value": "3",
      "text": "Recyclability of materials"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question8.4",
  "title": "When deciding on the architecture in the construction or reconstruction we paid attention to the following:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Minimization of energy and resources in later operation"
    },
     {
      "value": "2",
      "text": "Minimization of land consumption"
    },
     {
      "value": "3",
      "text": "Energy-saving architecture"
    },
     {
      "value": "4",
      "text": "Efficient use of materials (material reduction)"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "checkbox",
  "name": "question8.5",
  "title": "We keep the buildings at the current state of the art in the following areas:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Insulation"
    },
     {
      "value": "2",
      "text": "Multiple glazing"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "checkbox",
  "name": "question8.6",
  "title": "During the construction as well as reconstruction of the accommodation, waste building materials are:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Reused in our own house"
    },
     {
      "value": "2",
      "text": "Recycled"
    },
     {
      "value": "3",
      "text": "Passed on"
    },
     {
      "value": "4",
      "text": "Disposed of in a sustainable manner"
    },
  ],
  "separateSpecialChoices": true,
  "hasNone": true,
  "noneText": "None",
}
,{
  "type": "checkbox",
  "name": "question8.7",
  "title": "We pay attention to the following sustainability aspects when furnishing the rooms with furniture and decoration:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Use of regional materials (<200km)"
    },
     {
      "value": "2",
      "text": "Use of certified materials, e.g. plastic free/ certified wood (FSC)/ organic materials"
    },
     {
      "value": "3",
      "text": "Support of local workshops, artists and other suppliers"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question8.8",
  "title": "We pay attention to native plants and natural garden architecture when designing the outdoor facilities",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.9",
  "title": "The hotel embeds itself in the surrounding landscape and culture. The following will be considered:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Cultural heritage (both buildings and intangible cultural heritage, such as the practice of customs and traditions)"
    },
     {
      "value": "2",
      "text": "Natural heritage (consideration of endangered fauna and flora), active nature conservation"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question8.10",
  "title": "Impacts on flora and fauna are regularly analyzed and measures are taken for their protection",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "checkbox",
  "name": "question8.11",
  "title": "We support fauna and flora through the following measures:",
  "isRequired": true,
  "choices": [
     {
      "value": "1",
      "text": "Creation of natural meadows"
    },
     {
      "value": "2",
      "text": "Shelter for insects e.g. wild bee hotel"
    },
  ],
  "separateSpecialChoices": true,
  "hasOther": true,
  "hasNone": true,
  "otherPlaceHolder": "...",
  "noneText": "None",
  "otherText": "Other"
}
,{
  "type": "boolean",
  "name": "question8.12",
  "title": "During the construction as well as operation of the shelter we preserve cultural aspects of the environment and maintain archaeological cities (if applicable)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}
,{
  "type": "boolean",
  "name": "question8.13",
  "title": "We consider risk factors and implement preventive measures to avoid environmental disasters (if applicable)",
  "defaultValue": "false",
  "labelTrue": "Yes",
  "labelFalse": "No",
  "isRequired": true
}

 ] 
 },]
}
 const defaultSurveyCSS = {   header: "bg-secondary text-white p-3" 
 }; 
const defaultSurveyDATA = {};
const defaultSurveyConfigEN = { 
   defaultSurveyCSS, 
   defaultSurveyDATA, 
   defaultSurveyJSONEN, 
}; 
export default defaultSurveyConfigEN;