// import "survey-react/defaultV2.css"
// Modern theme
// import 'survey-core/modern.min.css';
import * as Survey from "survey-react";
import defaultSurveyConfigDE from "./config/surveyDE";
import defaultSurveyConfigEN from "./config/surveyEN";
import defaultSurveyConfigFR from "./config/surveyFR";

import { useState } from "react";
import React from "react";
import "./index.css";

// StylesManager.applyTheme("defaultV2");
var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$font-family"] = "Montserrat";
defaultThemeColors["$main-color"] = "#DDC180";
defaultThemeColors["$main-hover-color"] = "#3C3C3B";
defaultThemeColors["$body-background-color"] = "#ececec";
defaultThemeColors["$text-color"] = "#A98C59";
defaultThemeColors["$header-color"] = "#DDC180";
defaultThemeColors["$header-background-color"] = "#3C3C3B";
defaultThemeColors["$body-container-background-color"] = "#ececec";

Survey.StylesManager.applyTheme("default");

const storageName = "sustainability_survey_store";
const apiEndpoint = "https://api.chargeholidays.com/sustainabilitycheck/";
// var apiEndpoint = "http://localhost:1337/sustainabilitycheck/";

function saveState(survey, selects) {
  var res = {
    currentPageNo: survey.currentPageNo,
    data: survey.data,
    selects: selects,
  };
  //Here should be the code to save the data into your database
  window.localStorage.setItem(storageName, JSON.stringify(res));
}

function clearStorage(selects) {
  window.localStorage.removeItem(storageName);
  var res = {
    selects: selects,
  };
  //Here should be the code to save the data into your database
  window.localStorage.setItem(storageName, JSON.stringify(res));
}

function clearStorageAndReload(selects) {
  clearStorage(selects);
  window.location.reload();
}

function langHelper(sel) {
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get("lang");
  if (sel === undefined) {
    switch (lang) {
      case "en":
        sel = "English";
        break;
      case "de":
        sel = "Deutsch";
        break;
      case "fr":
        sel = "Francais";
        break;
      default:
        sel = undefined;
    }
  }
  let surveyjson = defaultSurveyConfigEN.defaultSurveyJSONEN;
  if (sel === "Deutsch") {
    surveyjson = defaultSurveyConfigDE.defaultSurveyJSONDE;
  } else if (sel === "Francais") {
    surveyjson = defaultSurveyConfigFR.defaultSurveyJSONFR;
  }

  return [surveyjson, sel];
}

function test(sel) {
  switch (sel) {
    case "Deutsch":
      return "Daten löschen";
    case "Francais":
      return "Effacer les données";
    default:
      return "Clear data";
  }
}

function App() {
  const [selects, setSelects] = useState();

  // const queryParameters = new URLSearchParams(window.location.search);
  // const checkId = queryParameters.get("checkId")

  var sel = selects;

  // Restore survey results
  const prevData = window.localStorage.getItem(storageName) || null;
  var data = {};
  var pageNo = 0;

  if (prevData) {
    var rawData = JSON.parse(prevData);
    data = rawData.data;
    if (rawData.currentPageNo) {
      pageNo = rawData.currentPageNo;
    }
    if (!selects && rawData.selects) {
      sel = rawData.selects;
    }
  }

  let surveyjson;
  [surveyjson, sel] = langHelper(sel);

  return (
    <div className="App">
      <div className="langSelectContainer">
        <select
          className="langSelector"
          value={sel}
          onChange={(e) => setSelects(e.target.value)}
        >
          <option>English</option>
          <option>Deutsch</option>
          <option>Francais</option>
        </select>

        <button
          className="clearButton"
          onClick={(_) => clearStorageAndReload(sel)}
        >
          <p> {test(sel)}</p>
        </button>
      </div>
      <Survey.Survey
        //css={header}
        data={data}
        currentPageNo={pageNo}
        json={surveyjson}
        showProgressBar="bottom"
        showPageNumbers
        onValueChanged={(survey) => {
          saveState(survey, sel);
        }}
        onCurrentPageChanged={(survey) => {
          saveState(survey, sel);
        }}
        onComplete={async (survey) => {
          // save data to cache in case of error
          saveState(survey, sel);
          // Send data to the backend via POST
          try {
            var res = await fetch(apiEndpoint, {
              method: "POST",
              mode: "cors",
              body: JSON.stringify(survey.data), // body data type must match "Content-Type" header
            });

            if (!res.ok) {
              throw new Error(res.statusText);
            }
            clearStorage(sel);
          } catch (e) {
            console.log(JSON.stringify(survey.data));
            console.log(e);
            alert(
              "Error submitting please retry, if error persist copy survey data from console "
            );
            window.location.reload();
            return;
          }
        }}
      />
    </div>
  );
}

export default App;
